<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
  >
    <g id="SVG">
      <g id="Home-(v2)-NEW">
        <g id="Tablet">
          <g id="FETAURED-SPECIALS">
            <g id="Featured-specials-header">
              <g id="arrows">
                <g id="Group-14">
                  <g id="icon-/-caret-right-/-blue">
                    <path
                      id="Fill-16"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.3871 8.66216C9.96806 8.25483 9.28847 8.25483 8.86903 8.66216L1.04187 16.2392C0.622436 16.6465 0.622436 17.3032 1.04187 17.7105C1.46088 18.1137 2.14047 18.1137 2.55948 17.7105L10.3871 10.1335C10.8061 9.72618 10.8061 9.06948 10.3871 8.66216ZM1.04187 0.860706C0.622436 1.26803 0.622436 1.92473 1.04187 2.33205L6.17812 7.30303C7.01656 8.11352 8.37575 8.11352 9.21377 7.30303L2.55948 0.860706C2.14047 0.457542 1.46088 0.457542 1.04187 0.860706Z"
                      fill="black"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Home-(v2)-NEW_2">
        <g id="Tablet_2">
          <g id="FETAURED-SPECIALS_2">
            <g id="Featured-specials-header_2">
              <g id="arrows_2">
                <g id="Group-14_2">
                  <g id="icon-/-caret-right-/-blue_2">
                    <path
                      id="Fill-16_2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.0415 8.66216C12.4605 8.25483 13.1401 8.25483 13.5596 8.66216L21.3867 16.2392C21.8062 16.6465 21.8062 17.3032 21.3867 17.7105C20.9677 18.1137 20.2881 18.1137 19.8691 17.7105L12.0415 10.1335C11.6225 9.72618 11.6225 9.06948 12.0415 8.66216ZM21.3867 0.860706C21.8062 1.26803 21.8062 1.92473 21.3867 2.33205L16.2505 7.30303C15.412 8.11352 14.0528 8.11352 13.2148 7.30303L19.8691 0.860706C20.2881 0.457543 20.9677 0.457542 21.3867 0.860706Z"
                      fill="black"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
